/* eslint-disable promise/param-names */
import axios from 'axios';
import moment from 'moment';

const state = {
  token: localStorage.getItem("user-token") || "",
  user: {},
  status: "",
  errorMessage: "",
  hasLoadedOnce: false
};

const getters = {
  isAuthenticated: state => !!state.token,
  isManagement: state => state.user && state.user.email === 'admin@admin.com',
  authStatus: state => state.status,
  authErrorMessage: stage => state.errorMessage || "Erro desconhecido",
  sessionUser: state => state.user,
  isOnFirstSevenDays: state => {
    const userCreatedAt = state.user.createdAt;
    return moment(userCreatedAt).isAfter(moment().subtract(7, 'days'));
  },
  remainingDays: state => {
    const userCreatedAt = state.user.createdAt;
    const remainingDays = moment(userCreatedAt).add(7, 'days').diff(moment(), 'days');
    return remainingDays;
  },
  // token: state => state.token
};

const actions = {
  SESSION_RESTORE: async ({ commit, dispatch, rootState }) => {
    console.log('Restaura a sessão c/', rootState.auth.token);
    commit('TOKEN', rootState.auth.token);
    commit('AUTH_REQUEST');
    try {
      const response = await axios.get('/users/self');
      commit('AUTH_SUCCESS', {
        user: response.data.user,
        token: rootState.auth.token
      });
    } catch (e) {
      commit('AUTH_ERROR');

      dispatch('LOGOUT'); // Deslogar caso erro 401 - Forbidden
    }
  },
  AUTHENTICATE: async ({ commit, dispatch }, user) => {
    commit('AUTH_REQUEST');
    try {
      const response = await axios.post('/auth/login', user);
      if (response.isAxiosError) throw response;
      commit('TOKEN', response.data?.token);
      commit('AUTH_SUCCESS', response.data);
    } catch (e) {
      commit('AUTH_ERROR',e);
      dispatch('LOGOUT'); // Deslogar caso erro 401 - Forbidden
      return Promise.reject(e);
    }
  },
  REGISTER: async ({ commit, dispatch }, user) => {
    commit('AUTH_REQUEST');
    try {
      const response = await axios.post('/auth/register', user);
      commit('TOKEN', response.data.token);
      commit('AUTH_SUCCESS', response.data);
    } catch (e) {
      commit('AUTH_ERROR',e);
      return Promise.reject(e);
    }
  },
  LOGOUT: ({ commit }) => {
    return new Promise(resolve => {
      commit('AUTH_LOGOUT');
      localStorage.removeItem("user-token");
      resolve();
    });
  }
};

const mutations = {
  AUTH_REQUEST: state => {
    state.status = "loading";
  },
  AUTH_SUCCESS: (state, resp) => {
    state.status = "success";
    state.token = resp.token;
    state.user = resp.user;
    state.hasLoadedOnce = true;
  },
  AUTH_ERROR: (state, error) => {
    console.log(error)
    state.errorMessage = error && error.response && error.response.data && error.response.data.errorMessage;
    localStorage.removeItem("user-token");
    state.status = "error";
    state.hasLoadedOnce = true;
  },
  AUTH_LOGOUT: state => {
    state.token = "";
  },
  TOKEN: (state, token) => {
    localStorage.setItem("user-token", token);
    state.token = token;
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
