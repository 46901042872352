<template>
  <div class="user-edit" v-if="user">
    <div class="card border-0">
      <b-container fluid="sm">
        <h3 class="border-bottom border-gray pb-2 pt-3">
          <span v-if="isNewUser">Criar</span><span v-else>Editar</span> Usuário
        </h3>

        <b-form @submit.prevent="submitForm">
          <b-form-group label="Nome:" label-for="name">
            <b-form-input id="name" v-model="user.name" required></b-form-input>
          </b-form-group>
          <b-form-group label="E-mail:" label-for="email">
            <b-form-input id="email" v-model="user.email" type="email" required :disabled="!isManagement"></b-form-input>
          </b-form-group>
          <b-form-group label="Senha:" label-for="password">
            <b-form-input id="password" v-model="user.password" type="password" required></b-form-input>
          </b-form-group>
          <b-form-group label="Confirmar Senha:" label-for="confirmPassword">
            <b-form-input id="confirmPassword" v-model="user.confirmPassword" type="password" required></b-form-input>
          </b-form-group>

          <b-form-group v-if="isManagement"  label="Status:" label-for="active">
            <b-form-checkbox id="active" v-model="user.active" switch>
              Ativo
            </b-form-checkbox>
          </b-form-group>

          <b-row align-h="end" >
            <b-col cols="3">
              <router-link :to="{ name: isManagement ? 'list-users' : 'home' }" class="btn btn-outline-primary ml-3 float-right">
                Voltar
              </router-link>
              <button type="button" class="btn btn-primary ml-3 float-right" @click="save">Salvar</button>
            </b-col>
          </b-row>
        </b-form>
      </b-container>

    </div>
  </div>
</template>

<script>
import UserService from '@/services/UserService';
import { mapGetters } from 'vuex';

export default {
  components: {},

  data() {
    return {
      user: {
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
        active: false,
      },
    };
  },

  created() {
    this.fetchData();
  },

  computed: {
    ...mapGetters(['isManagement']),

    isNewUser() {
      return this.$route.params.id === 'new';
    },
  },

  methods: {
    async fetchData() {
      if (!this.isNewUser) {
        const { user } = await UserService.findById(this.$route.params.id)
        this.user = {
          ...this.user,
          ...user
        };
      }
    },

    async save() {
      try {
        const data = {
          ...this.user,
          active: this.user.active ? 1 : 0,
        };

        if (this.isNewUser) {
          await UserService.create(data);
        } else {
          if (data.password) {
            if (!data.confirmPassword || (data.confirmPassword !== data.password)) {
              this.$swal.fire({
                icon: 'error',
                title: 'Falha ao salvar usuário',
                text: 'Senha e Confirmação de Senha devem ser iguais',
              });

              return;
            }
          }

          const { user } = await UserService.update(data.id, data);

          this.user = { ...this.user, ...user };

          this.$swal.fire('Pronto!',
          `Usuário atualizado com sucesso!`,
          'success');
        }
      } catch (e) {
        return this.$swal.fire({
          icon: 'error',
          title: 'Falha ao salvar usuário',
          text: e.response.data.errorMessage,
        });
      }
    }
  },
}
</script>
