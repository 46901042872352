<template>
  <div class="calculations-wrapper p-3">
    <b-card class="mt-3 mb-3 shadow-sm" id="section-to-print" no-body v-if="calculation">
      <div class="row mx-0 p-3 bg-gray-100 border-bottom d-flex justify-content-between">
        <h5 class="font-weight-bold mb-0 text-gray-700">Dados do Cálculo</h5>
      </div>
      <div class="row mx-0">
        <div class="col-md-3 px-0 border-right border-bottom border-gray-200">
          <div class="p-3">
            <h6 class="text-uppercase text-gray-600 small">Nome do Cálculo</h6>
            <div v-if="isEditing">
              <input type="text" class="form-control form-control-sm" v-model="calculation.name" v-on:blur="inputValidation('name')" />
              <ValidationMessage field="name" v-bind:validations="validations" />
            </div>
            <TextInfo :isLoading="isLoading" :text="calculation.name" v-else />
          </div>
        </div>
        <div class="col-md-3 px-0 border-right border-bottom border-gray-200">
          <div class="p-3">
            <h6 class="text-uppercase text-gray-600 small">Dt. Criação</h6>
            <TextInfo :isLoading="isLoading" :text="calculation.createdAt" />
          </div>
        </div>
        <div class="col-md-3 px-0 border-right border-bottom border-gray-200">
          <div class="p-3">
            <h6 class="text-uppercase text-gray-600 small">Dt. Para Atualização</h6>
            <div v-if="isEditing">
              <InputDatePicker v-model="calculation.calculationDate" v-on:blur="inputValidation('calculationDate')" :ignoreLimit="true" />
              <ValidationMessage field="calculationDate" v-bind:validations="validations" />
            </div>
            <TextInfo :isLoading="isLoading" :text="calculation.calculationDate" v-else />
          </div>
        </div>
        <div class="col-md-3 px-0 border-right border-bottom border-gray-200">
          <div class="p-3">
            <h6 class="text-uppercase text-gray-600 small">Regime de Previdência</h6>
            <div v-if="isEditing">
              <b-form-select
                size="sm"
                id="inline-form-custom-select-pref"
                class="mb-2 mr-sm-2 mb-sm-0 col-sm-12"
                :options="[{ text: 'Escolha...', value: null }, 'União', 'Curitiba', 'PR', 'SP', 'ES', 'RS', 'MT', 'PB', 'Sem Reforma']"
                v-model="calculation.regime"
              />
              <ValidationMessage field="regime" v-bind:validations="validations" />
            </div>
            <TextInfo :isLoading="isLoading" :text="calculation.regime" v-else />
          </div>
        </div>
      </div>
      <div class="row mx-0">
        <div class="col-md-2 px-0 border-right border-bottom border-gray-200">
          <div class="p-3">
            <h6 class="text-uppercase text-gray-600 small">Dt. Ingresso no Serv. Público</h6>
            <div v-if="isEditing">
              <InputDatePicker v-model="calculation.publicServiceIngressDate" v-on:blur="inputValidation('publicServiceIngressDate')" />
              <ValidationMessage field="publicServiceIngressDate" v-bind:validations="validations" />
            </div>
            <TextInfo
              :isLoading="isLoading"
              :text="publicServiceIngressDate"
              :tooltipInfo="isEditing && workRecords.length ? workRecordMessage : ''"
              v-else
            />
          </div>
        </div>
        <div class="col-md-2 px-0 border-right border-bottom border-gray-200">
          <div class="p-3">
            <h6 class="text-uppercase text-gray-600 small">Tempo de Serviço Público</h6>

            <div class="row">
              <div class="col-6 text-gray-500 font-italic">
                <input v-if="isEditing && !workRecords.length" type="number" min="0" class="form-control form-control-sm" v-model="calculation.publicServiceTimeYears" />
                <TextInfo
                  :isLoading="isLoading"
                  emptyText="-"
                  :text="publicServiceTime.years"
                  :tooltipInfo="isEditing && publicServiceTime ? workRecordMessage : ''"
                  v-else
                />
              </div>
              <div class="col-6 text-gray-500 font-italic">Ano(s)</div>
            </div>

            <div class="row">
              <div class="col-6">
                <input v-if="isEditing && !workRecords.length" type="number" min="0" class="mt-1 form-control form-control-sm" v-model="calculation.publicServiceTimeMonths" />
                <TextInfo
                  :isLoading="isLoading"
                  emptyText="-"
                  :text="publicServiceTime.months"
                  :tooltipInfo="isEditing && publicServiceTime ? workRecordMessage : ''"
                  v-else
                />
              </div>
              <div class="col-6 text-gray-500 font-italic">Mês(es)</div>
            </div>

            <div class="row">
              <div class="col-6">
                <input v-if="isEditing && !workRecords.length" type="number" min="0" class="mt-1 form-control form-control-sm" v-model="calculation.publicServiceTimeDays" />
                <TextInfo
                  :isLoading="isLoading"
                  emptyText="-"
                  :text="publicServiceTime.days"
                  :tooltipInfo="isEditing && publicServiceTime ? workRecordMessage : ''"
                  v-else
                />
              </div>
              <div class="col-6 text-gray-500 font-italic">Dia(s)</div>
            </div>
              
            <ValidationMessage field="publicServiceTime" v-bind:validations="validations" />
          </div>
        </div>
        <div class="col-md-2 px-0 border-right border-bottom border-gray-200">
          <div class="p-3">
            <h6 class="text-uppercase text-gray-600 small">Tempo de Magistério</h6>

            <div class="row">
              <div class="col-6 text-gray-500 font-italic">
                <input v-if="isEditing && !workRecords.length" type="number" min="0" class="form-control form-control-sm" v-model="calculation.teachingTimeYears" />
                <TextInfo
                  :isLoading="isLoading"
                  emptyText="-"
                  :text="teachingTime.years"
                  :tooltipInfo="isEditing && teachingTime ? workRecordMessage : ''"
                  v-else
                />
              </div>
              <div class="col-6 text-gray-500 font-italic">Ano(s)</div>
            </div>

            <div class="row">
              <div class="col-6">
                <input v-if="isEditing && !workRecords.length" type="number" min="0" class="mt-1 form-control form-control-sm" v-model="calculation.teachingTimeMonths" />
                <TextInfo
                  :isLoading="isLoading"
                  emptyText="-"
                  :text="teachingTime.months"
                  :tooltipInfo="isEditing && teachingTime ? workRecordMessage : ''"
                  v-else
                />
              </div>
              <div class="col-6 text-gray-500 font-italic">Mês(es)</div>
            </div>

            <div class="row">
              <div class="col-6">
                <input v-if="isEditing && !workRecords.length" type="number" min="0" class="mt-1 form-control form-control-sm" v-model="calculation.teachingTimeDays" />
                <TextInfo
                  :isLoading="isLoading"
                  emptyText="-"
                  :text="teachingTime.days"
                  :tooltipInfo="isEditing && teachingTime ? workRecordMessage : ''"
                  v-else
                />
              </div>
              <div class="col-6 text-gray-500 font-italic">Dia(s)</div>
            </div>
              
            <ValidationMessage field="teachingTime" v-bind:validations="validations" />
          </div>
        </div>
        <div class="col-md-2 px-0 border-right border-bottom border-gray-200">
          <div class="p-3">
            <h6 class="text-uppercase text-gray-600 small">Tempo de Carreira</h6>

            <div class="row">
              <div class="col-6 text-gray-500 font-italic">
                <input v-if="isEditing && !workRecords.length" type="number" min="0" class="form-control form-control-sm" v-model="calculation.carreerTimeYears" />
                <TextInfo
                  :isLoading="isLoading"
                  emptyText="-"
                  :text="carreerTime.years"
                  :tooltipInfo="isEditing && carreerTime ? workRecordMessage : ''"
                  v-else
                />
              </div>
              <div class="col-6 text-gray-500 font-italic">Ano(s)</div>
            </div>

            <div class="row">
              <div class="col-6">
                <input v-if="isEditing && !workRecords.length" type="number" min="0" class="mt-1 form-control form-control-sm" v-model="calculation.carreerTimeMonths" />
                <TextInfo
                  :isLoading="isLoading"
                  emptyText="-"
                  :text="carreerTime.months"
                  :tooltipInfo="isEditing && carreerTime ? workRecordMessage : ''"
                  v-else
                />
              </div>
              <div class="col-6 text-gray-500 font-italic">Mês(es)</div>
            </div>

            <div class="row">
              <div class="col-6">
                <input v-if="isEditing && !workRecords.length" type="number" min="0" class="mt-1 form-control form-control-sm" v-model="calculation.carreerTimeDays" />
                <TextInfo
                  :isLoading="isLoading"
                  emptyText="-"
                  :text="carreerTime.days"
                  :tooltipInfo="isEditing && carreerTime ? workRecordMessage : ''"
                  v-else
                />
              </div>
              <div class="col-6 text-gray-500 font-italic">Dia(s)</div>
            </div>
              
            <ValidationMessage field="carreerTime" v-bind:validations="validations" />
          </div>
        </div>
        <div class="col-md-2 px-0 border-right border-bottom border-gray-200">
          <div class="p-3">
            <h6 class="text-uppercase text-gray-600 small">Tempo no Cargo</h6>

            <div class="row">
              <div class="col-6 text-gray-500 font-italic">
                <input v-if="isEditing && !workRecords.length" type="number" min="0" class="form-control form-control-sm" v-model="calculation.positionTimeYears" />
                <TextInfo
                  :isLoading="isLoading"
                  emptyText="-"
                  :text="positionTime.years"
                  :tooltipInfo="isEditing && positionTime ? workRecordMessage : ''"
                  v-else
                />
              </div>
              <div class="col-6 text-gray-500 font-italic">Ano(s)</div>
            </div>

            <div class="row">
              <div class="col-6">
                <input v-if="isEditing && !workRecords.length" type="number" min="0" class="mt-1 form-control form-control-sm" v-model="calculation.positionTimeMonths" />
                <TextInfo
                  :isLoading="isLoading"
                  emptyText="-"
                  :text="positionTime.months"
                  :tooltipInfo="isEditing && positionTime ? workRecordMessage : ''"
                  v-else
                />
              </div>
              <div class="col-6 text-gray-500 font-italic">Mês(es)</div>
            </div>

            <div class="row">
              <div class="col-6">
                <input v-if="isEditing && !workRecords.length" type="number" min="0" class="mt-1 form-control form-control-sm" v-model="calculation.positionTimeDays" />
                <TextInfo
                  :isLoading="isLoading"
                  emptyText="-"
                  :text="positionTime.days"
                  :tooltipInfo="isEditing && positionTime ? workRecordMessage : ''"
                  v-else
                />
              </div>
              <div class="col-6 text-gray-500 font-italic">Dia(s)</div>
            </div>
              
            <ValidationMessage field="positionTime" v-bind:validations="validations" />
          </div>
        </div>
        <div class="col-md-2 px-0 border-right border-bottom border-gray-200">
          <div class="p-3">
            <h6 class="text-uppercase text-gray-600 small">Tempo de Contribuição</h6>

            <div class="row">
              <div class="col-6 text-gray-500 font-italic">
                <input v-if="isEditing && !workRecords.length" type="number" min="0" class="form-control form-control-sm" v-model="calculation.contributionTimeYears" />
                <TextInfo
                  :isLoading="isLoading"
                  emptyText="-"
                  :text="contributionTime.years"
                  :tooltipInfo="isEditing && contributionTime ? workRecordMessage : ''"
                  v-else
                />
              </div>
              <div class="col-6 text-gray-500 font-italic">Ano(s)</div>
            </div>

            <div class="row">
              <div class="col-6">
                <input v-if="isEditing && !workRecords.length" type="number" min="0" class="mt-1 form-control form-control-sm" v-model="calculation.contributionTimeMonths" />
                <TextInfo
                  :isLoading="isLoading"
                  emptyText="-"
                  :text="contributionTime.months"
                  :tooltipInfo="isEditing && contributionTime ? workRecordMessage : ''"
                  v-else
                />
              </div>
              <div class="col-6 text-gray-500 font-italic">Mês(es)</div>
            </div>

            <div class="row">
              <div class="col-6">
                <input v-if="isEditing && !workRecords.length" type="number" min="0" class="mt-1 form-control form-control-sm" v-model="calculation.contributionTimeDays" />
                <TextInfo
                  :isLoading="isLoading"
                  emptyText="-"
                  :text="contributionTime.days"
                  :tooltipInfo="isEditing && contributionTime ? workRecordMessage : ''"
                  v-else
                />
              </div>
              <div class="col-6 text-gray-500 font-italic">Dia(s)</div>
            </div>
              
            <ValidationMessage field="contributionTime" v-bind:validations="validations" />
          </div>
        </div>
      </div>
      <div class="row mx-0" v-if="showMore">
        <div class="col-md-2 px-0 border-right border-bottom border-gray-200">
          <div class="p-3">
            <h6 class="text-uppercase text-gray-600 small">Tempo Especial</h6>

            <div class="row">
              <div class="col-6 text-gray-500 font-italic">
                <input v-if="isEditing && !workRecords.length" type="number" min="0" class="form-control form-control-sm" v-model="calculation.specialTimeYears" />
                <TextInfo
                  :isLoading="isLoading"
                  emptyText="-"
                  :text="specialTime.years"
                  :tooltipInfo="isEditing && specialTime ? workRecordMessage : ''"
                  v-else
                />
              </div>
              <div class="col-6 text-gray-500 font-italic">Ano(s)</div>
            </div>

            <div class="row">
              <div class="col-6">
                <input v-if="isEditing && !workRecords.length" type="number" min="0" class="mt-1 form-control form-control-sm" v-model="calculation.specialTimeMonths" />
                <TextInfo
                  :isLoading="isLoading"
                  emptyText="-"
                  :text="specialTime.months"
                  :tooltipInfo="isEditing && specialTime ? workRecordMessage : ''"
                  v-else
                />
              </div>
              <div class="col-6 text-gray-500 font-italic">Mês(es)</div>
            </div>

            <div class="row">
              <div class="col-6">
                <input v-if="isEditing && !workRecords.length" type="number" min="0" class="mt-1 form-control form-control-sm" v-model="calculation.specialTimeDays" />
                <TextInfo
                  :isLoading="isLoading"
                  emptyText="-"
                  :text="specialTime.days"
                  :tooltipInfo="isEditing && specialTime ? workRecordMessage : ''"
                  v-else
                />
              </div>
              <div class="col-6 text-gray-500 font-italic">Dia(s)</div>
            </div>
              
            <ValidationMessage field="specialTime" v-bind:validations="validations" />
          </div>
        </div>
        <div class="col-md-2 px-0 border-right border-bottom border-gray-200">
          <div class="p-3">
            <h6 class="text-uppercase text-gray-600 small">Tempo Com Deficiência</h6>

            <div class="row">
              <div class="col-6 text-gray-500 font-italic">
                <input v-if="isEditing && !workRecords.length" type="number" min="0" class="form-control form-control-sm" v-model="calculation.disabledTimeYears" />
                <TextInfo
                  :isLoading="isLoading"
                  emptyText="-"
                  :text="disabledTime.years"
                  :tooltipInfo="isEditing && disabledTime ? workRecordMessage : ''"
                  v-else
                />
              </div>
              <div class="col-6 text-gray-500 font-italic">Ano(s)</div>
            </div>

            <div class="row">
              <div class="col-6">
                <input v-if="isEditing && !workRecords.length" type="number" min="0" class="mt-1 form-control form-control-sm" v-model="calculation.disabledTimeMonths" />
                <TextInfo
                  :isLoading="isLoading"
                  emptyText="-"
                  :text="disabledTime.months"
                  :tooltipInfo="isEditing && disabledTime ? workRecordMessage : ''"
                  v-else
                />
              </div>
              <div class="col-6 text-gray-500 font-italic">Mês(es)</div>
            </div>

            <div class="row">
              <div class="col-6">
                <input v-if="isEditing && !workRecords.length" type="number" min="0" class="mt-1 form-control form-control-sm" v-model="calculation.disabledTimeDays" />
                <TextInfo
                  :isLoading="isLoading"
                  emptyText="-"
                  :text="disabledTime.days"
                  :tooltipInfo="isEditing && disabledTime ? workRecordMessage : ''"
                  v-else
                />
              </div>
              <div class="col-6 text-gray-500 font-italic">Dia(s)</div>
            </div>
              
            <ValidationMessage field="disabledTime" v-bind:validations="validations" />
          </div>
        </div>
        <div class="col-md-2 px-0 border-right border-bottom border-gray-200">
          <div class="p-3">
            <h6 class="text-uppercase text-gray-600 small">Tempo Com Deficiência Leve</h6>

            <div class="row">
              <div class="col-6 text-gray-500 font-italic">
                <input v-if="isEditing && !workRecords.length" type="number" min="0" class="form-control form-control-sm" v-model="calculation.disabledLowTimeYears" />
                <TextInfo
                  :isLoading="isLoading"
                  emptyText="-"
                  :text="disabledLowTime.years"
                  :tooltipInfo="isEditing && disabledLowTime ? workRecordMessage : ''"
                  v-else
                />
              </div>
              <div class="col-6 text-gray-500 font-italic">Ano(s)</div>
            </div>

            <div class="row">
              <div class="col-6">
                <input v-if="isEditing && !workRecords.length" type="number" min="0" class="mt-1 form-control form-control-sm" v-model="calculation.disabledLowTimeMonths" />
                <TextInfo
                  :isLoading="isLoading"
                  emptyText="-"
                  :text="disabledLowTime.months"
                  :tooltipInfo="isEditing && disabledLowTime ? workRecordMessage : ''"
                  v-else
                />
              </div>
              <div class="col-6 text-gray-500 font-italic">Mês(es)</div>
            </div>

            <div class="row">
              <div class="col-6">
                <input v-if="isEditing && !workRecords.length" type="number" min="0" class="mt-1 form-control form-control-sm" v-model="calculation.disabledLowTimeDays" />
                <TextInfo
                  :isLoading="isLoading"
                  emptyText="-"
                  :text="disabledLowTime.days"
                  :tooltipInfo="isEditing && disabledLowTime ? workRecordMessage : ''"
                  v-else
                />
              </div>
              <div class="col-6 text-gray-500 font-italic">Dia(s)</div>
            </div>
              
            <ValidationMessage field="disabledLowTime" v-bind:validations="validations" />
          </div>
        </div>
        <div class="col-md-2 px-0 border-right border-bottom border-gray-200">
          <div class="p-3">
            <h6 class="text-uppercase text-gray-600 small">Tempo Com Deficiência Moderada</h6>

            <div class="row">
              <div class="col-6 text-gray-500 font-italic">
                <input v-if="isEditing && !workRecords.length" type="number" min="0" class="form-control form-control-sm" v-model="calculation.disabledMediumTimeYears" />
                <TextInfo
                  :isLoading="isLoading"
                  emptyText="-"
                  :text="disabledMediumTime.years"
                  :tooltipInfo="isEditing && disabledMediumTime ? workRecordMessage : ''"
                  v-else
                />
              </div>
              <div class="col-6 text-gray-500 font-italic">Ano(s)</div>
            </div>

            <div class="row">
              <div class="col-6">
                <input v-if="isEditing && !workRecords.length" type="number" min="0" class="mt-1 form-control form-control-sm" v-model="calculation.disabledMediumTimeMonths" />
                <TextInfo
                  :isLoading="isLoading"
                  emptyText="-"
                  :text="disabledMediumTime.months"
                  :tooltipInfo="isEditing && disabledMediumTime ? workRecordMessage : ''"
                  v-else
                />
              </div>
              <div class="col-6 text-gray-500 font-italic">Mês(es)</div>
            </div>

            <div class="row">
              <div class="col-6">
                <input v-if="isEditing && !workRecords.length" type="number" min="0" class="mt-1 form-control form-control-sm" v-model="calculation.disabledMediumTimeDays" />
                <TextInfo
                  :isLoading="isLoading"
                  emptyText="-"
                  :text="disabledMediumTime.days"
                  :tooltipInfo="isEditing && disabledMediumTime ? workRecordMessage : ''"
                  v-else
                />
              </div>
              <div class="col-6 text-gray-500 font-italic">Dia(s)</div>
            </div>
              
            <ValidationMessage field="disabledMediumTime" v-bind:validations="validations" />
          </div>
        </div>
        <div class="col-md-2 px-0 border-right border-bottom border-gray-200">
          <div class="p-3">
            <h6 class="text-uppercase text-gray-600 small">Tempo Com Deficiência Grave</h6>

            <div class="row">
              <div class="col-6 text-gray-500 font-italic">
                <input v-if="isEditing && !workRecords.length" type="number" min="0" class="form-control form-control-sm" v-model="calculation.disabledHighTimeYears" />
                <TextInfo
                  :isLoading="isLoading"
                  emptyText="-"
                  :text="disabledHighTime.years"
                  :tooltipInfo="isEditing && disabledHighTime ? workRecordMessage : ''"
                  v-else
                />
              </div>
              <div class="col-6 text-gray-500 font-italic">Ano(s)</div>
            </div>

            <div class="row">
              <div class="col-6">
                <input v-if="isEditing && !workRecords.length" type="number" min="0" class="mt-1 form-control form-control-sm" v-model="calculation.disabledHighTimeMonths" />
                <TextInfo
                  :isLoading="isLoading"
                  emptyText="-"
                  :text="disabledHighTime.months"
                  :tooltipInfo="isEditing && disabledHighTime ? workRecordMessage : ''"
                  v-else
                />
              </div>
              <div class="col-6 text-gray-500 font-italic">Mês(es)</div>
            </div>

            <div class="row">
              <div class="col-6">
                <input v-if="isEditing && !workRecords.length" type="number" min="0" class="mt-1 form-control form-control-sm" v-model="calculation.disabledHighTimeDays" />
                <TextInfo
                  :isLoading="isLoading"
                  emptyText="-"
                  :text="disabledHighTime.days"
                  :tooltipInfo="isEditing && disabledHighTime ? workRecordMessage : ''"
                  v-else
                />
              </div>
              <div class="col-6 text-gray-500 font-italic">Dia(s)</div>
            </div>
              
            <ValidationMessage field="disabledHighTime" v-bind:validations="validations" />
          </div>
        </div>
        <div class="col-md-2 px-0 border-right border-bottom border-gray-200">
          <div class="p-3">
            <h6 class="text-uppercase text-gray-600 small">Cortes de Data</h6>
            <div>
              <b-form-select
                size="sm"
                id="inline-form-custom-select-pref"
                class="mb-2 mr-sm-2 mb-sm-0 col-sm-12"
                :options="dateOptions"
                v-model="dateOption"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row d-flex justify-content-between mt-2 mb-2 mr-2">
        <div>
          <button class="btn btn-info ml-4" @click="showMore = !showMore">{{ showMore ? 'Esconder' : 'Mostrar mais' }}</button>
        </div>
        <div>
          <router-link
            class="ml-2 btn btn-danger ml-3" title="Fechar" role="button" size="sm"
            v-bind:to="{ name: 'customer', params: { id: customerId } }"
          >
            Fechar
          </router-link>

          <button class="btn btn-warning ml-3" @click="createCalc(calculation)" v-if="isEditing && !calculation.id">Criar</button>
          <button class="btn btn-warning ml-3" @click="updateCalc(calculation)" v-else-if="isEditing && calculation.id">Salvar</button>
          <button class="btn btn-warning ml-3" @click="isEditing = true" v-else>Editar</button>
        </div>
      </div>

      <div class="bg-gray-100 border-bottom d-flex" v-if="calculationId">
        <router-link
          class="tab-link-item p-3"
          v-bind:to="{ name: 'work-records', params: { calculationId: calculationId } }"
        >
          Registros de tempo
        </router-link>

        <router-link
          class="tab-link-item p-3"
          v-bind:to="{ name: 'rules' }"
        >
          Regras Aplicáveis
        </router-link>

        <router-link
          class="tab-link-item p-3"
          v-bind:to="{ name: 'future-rules' }"
        >
          Regras Futuras
        </router-link>

        <router-link
          class="tab-link-item p-3"
          v-bind:to="{ name: 'rules-not-applicable' }"
        >
          Regras Não Aplicáveis
        </router-link>
        <span
          class="tab-link-item p-3"
        >
          Detalhes do Cálculo
        </span>
      </div>

      <router-view v-if="calculation.id" />
    </b-card>
  </div>
</template>

<script>
import axios from 'axios';
import { getCalculationById, createCalculation, updateCalculation } from '@/services/calculations';
import { mapGetters, mapActions } from 'vuex';
import ValidationMessage from '@/components/ValidationMessage.vue';
import InputDatePicker from '@/components/InputDatePicker.vue';
import TextInfo from '@/components/TextInfo.vue';
import moment from 'moment';
import { getRegimeDate, getTime } from '../../../utils/common';

export default {
  name: 'Calculations',
  components: {
    TextInfo, 
    InputDatePicker, 
    ValidationMessage,
  },

  data: () => {
    const initialCalculation = {
      name: null,
      customerId: null,
      state: null,
      regime: null,
      publicServiceIngressDate: null,
      publicServiceTime: null,
      teachingTimeYears: 0,
      teachingTimeMonths: 0,
      teachingTimeDays: 0,
      carreerTime: null,
      positionTime: null,
      contributionTimeYears: null,
      contributionTimeMonths: null,
      contributionTimeDays: null,
      publicServiceTimeDays: 0,
      publicServiceTimeMonths: 0,
      publicServiceTimeYears: 0,
      carreerTimeDays: 0,
      carreerTimeMonths: 0,
      carreerTimeYears: 0,
      positionTimeDays: 0,
      positionTimeMonths: 0,
      positionTimeYears: 0,
    }

    return {
      calculationId: null,
      initialCalculation,
      showCalculationForm: false,
      calculation: {},
      validations: {},
      customer: {},
      isEditing: false,
      isLoading: false,
      workRecordMessage: 'Não é possível alterar esse campo pois o sistema está considerando os dados dos registros de trabalho.',
      showMore: false,
      dateOption: null,
      dateOptions: [
        { text: 'Até Hoje', value: null },
        { text: 'Até 16/12/1998', value: '1998-12-16' },
        { text: 'Até 31/12/2003', value: '2003-12-31' },
        { text: 'Até a Data da Reforma', value: 'regime'},
      ]
    }
  },

  created() {
    this.$watch(
      () => this.$route.params,
      (toParams, previousParams) => {
        this.calculationId = toParams.calculationId;
        this.fetchCalculation(toParams.calculationId);
        this.loadWorkRecords(toParams.calculationId);
      }
    )

    if (!this.calculationId) {
      this.calculationId = this.$route.params.calculationId;
      this.fetchCalculation(this.$route.params.calculationId);
      this.loadWorkRecords(this.$route.params.calculationId);
    }
  },

  computed: {
    ...mapGetters('workRecords', ['workRecords']),

    customerId() {
      return this.$route.params.id;
    },

    publicServiceIngressDate() {
      const { calculation, workRecords } = this;

      if (calculation.publicServiceIngressDate) {
        return calculation.publicServiceIngressDate;
      }

      return null;

      // if (!workRecords.length) {
        // return calculation.publicServiceIngressDate;
      // }

      // const [date] = workRecords.map((workRecord) => workRecord.ingressDate).sort((a, b) => new Date(a) - new Date(b));
      // return moment(date).format('DD/MM/YYYY');
    },

    regimeDate() {
      return getRegimeDate(this.calculation.regime);
    },

    limitDate() {
      if (this.dateOption) {
        if (this.dateOption === "regime") return this.regimeDate;
        const date = moment(this.dateOption);
        return date;
      }
      return null;
    },

    publicServiceTime() {

      const { customer, calculation, workRecords } = this;

      if (!workRecords) {
        return {
          years: calculation.publicServiceTimeYears,
          months: calculation.publicServiceTimeMonths,
          days: calculation.publicServiceTimeDays,
        }
      }

      const gender = customer.gender;

      return getTime(workRecords, this.limitDate, 'isPublicService', gender, this.regimeDate);
    },

    teachingTime() {
      const { customer, calculation, workRecords } = this;

      if (!workRecords) {
        return {
          years: calculation.teachingTimeYears,
          months: calculation.teachingTimeMonths,
          days: calculation.teachingTimeDays,
        }
      }

      const gender = customer.gender;

      return getTime(workRecords, this.limitDate, 'isTeaching', gender, this.regimeDate);
    },

    carreerTime() {
      const { customer, calculation, workRecords } = this;

      if (!workRecords) {
        return {
          years: calculation.carreerTimeYears,
          months: calculation.carreerTimeMonths,
          days: calculation.carreerTimeDays,
        }
      }

      const gender = customer.gender;

      return getTime(workRecords, this.limitDate, 'isCurrentCareer', gender, this.regimeDate);
    },

    positionTime() {
      const { customer, calculation, workRecords } = this;

      if (!workRecords) {
        return {
          years: calculation.positionTimeYears,
          months: calculation.positionTimeMonths,
          days: calculation.positionTimeDays,
        }
      }

      const gender = customer.gender;

      return getTime(workRecords, this.limitDate, 'isCurrentPosition', gender, this.regimeDate);
    },

    contributionTime() {
      const { customer, calculation, workRecords } = this;

      if (!workRecords) {
        return {
          years: calculation.contributionTimeYears,
          months: calculation.contributionTimeMonths,
          days: calculation.contributionTimeDays,
        }
      }

      const gender = customer.gender;

      return getTime(workRecords, this.limitDate, null, gender, this.regimeDate);
    },

    specialTime() {
      const { customer, calculation, workRecords } = this;

      if (!workRecords) {
        return {
          years: 0,
          months: 0,
          days: 0,
        }
      }

      const gender = customer.gender;

      return getTime(workRecords, this.limitDate, 'isSpecialTime', gender, this.regimeDate);
    },

    disabledTime() {
      const { customer, calculation, workRecords } = this;

      if (!workRecords) {
        return {
          years: 0,
          months: 0,
          days: 0,
        }
      }

      const gender = customer.gender;

      return getTime(workRecords, this.limitDate, 'isDisabled', gender, this.regimeDate);
    },

    disabledLowTime() {
      const { customer, calculation, workRecords } = this;

      if (!workRecords) {
        return {
          years: 0,
          months: 0,
          days: 0,
        }
      }

      const gender = customer.gender;

      return getTime(workRecords, this.limitDate, 'isDisabled-low', gender, this.regimeDate);
    },

    disabledMediumTime() {
      const { customer, calculation, workRecords } = this;

      if (!workRecords) {
        return {
          years: 0,
          months: 0,
          days: 0,
        }
      }

      const gender = customer.gender;

      return getTime(workRecords, this.limitDate, 'isDisabled-medium', gender, this.regimeDate);
    },

    disabledHighTime() {
      const { customer, calculation, workRecords } = this;

      if (!workRecords) {
        return {
          years: 0,
          months: 0,
          days: 0,
        }
      }

      const gender = customer.gender;

      return getTime(workRecords, this.limitDate, 'isDisabled-high', gender, this.regimeDate);
    },
  },

  methods: {
    ...mapActions('workRecords', ['loadWorkRecords']),
    ...mapActions('calculations', ['loadCalculations']),

    async createCalc() {
      this.isLoading = true;

      this.calculation.customerId = this.customerId;

      const { calculation } = await createCalculation(this.calculation);

      this.calculation = calculation;

      this.isEditing = false;
      this.isLoading = false;
    },

    async updateCalc() {
      this.isLoading = true;
      this.isEditing = false;

      this.calculation.customerId = this.customerId;
      // this.calculation.publicServiceIngressDate = moment(this.calculation.publicServiceIngressDate).format('YYYY-MM-DD');
      // this.calculation.calculationDate = moment(this.calculation.calculationDate).format('YYYY-MM-DD');

      const { calculation } = await updateCalculation(this.calculationId, this.calculation);

      this.calculation = calculation;

      this.calculation.publicServiceIngressDate = moment(calculation.publicServiceIngressDate).format('DD/MM/YYYY');
      this.calculation.calculationDate = calculation.calculationDate ? moment(calculation.calculationDate).format('DD/MM/YYYY') : moment(calculation.updatedAt).format('DD/MM/YYYY') ;
      this.calculation.createdAt = moment(calculation.createdAt).format('DD/MM/YYYY');
      
      this.isEditing = false;
      this.isLoading = false;

      this.loadCalculations(this.customerId);
    },

    inputValidation(field) {
      let isValid = true;
      const { validations } = this;
      const isNormalField = !['contributionTime', 'teachingTime'].includes(field);
      
      if (isNormalField) {
        const value = String(this.calculation[field] || '').replace('Data inválida', '');

        if (!value) {
          validations[field] = 'Campo obrigatório';
          isValid = false;
        } else {
          validations[field] = '';
        }

        if (field === 'email') {
          const emailRegex = /\S+@\S+\.\S+/;
          if (value && !emailRegex.test(value)) {
            validations.email = 'E-mail inválido';
            isValid = false;
          }
        }

        if (field === 'name') {
          if (value && value.length > 27) {
            validations[field] = 'Nome muito longo.'
          }
        }
      }

      if (field = 'contributionTime') {
        if (!this.calculation.contributionTimeYears && !this.calculation.contributionTimeMonths && !this.calculation.contributionTimeDays) {
          validations.contributionTime = 'Campo obrigatório';
          isValid = false;
        } else {
          validations.contributionTime = '';
        }
      }

      if (field = 'teachingTime') {
        if (!this.calculation.teachingTimeYears && !this.calculation.teachingTimeMonths && !this.calculation.teachingTimeDays) {
          validations.teachingTime = 'Campo obrigatório';
          isValid = false;
        } else {
          validations.teachingTime = '';
        }
      }

      if (field = 'carreerTime') {
        if (!this.calculation.carreerTimeYears && !this.calculation.carreerTimeMonths && !this.calculation.carreerTimeDays) {
          validations.carreerTime = 'Campo obrigatório';
          isValid = false;
        } else {
          validations.carreerTime = '';
        }
      }

      if (field = 'publicServiceTime') {
        if (!this.calculation.publicServiceTimeYears && !this.calculation.publicServiceTimeMonths && !this.calculation.publicServiceTimeDays) {
          validations.publicServiceTime = 'Campo obrigatório';
          isValid = false;
        } else {
          validations.publicServiceTime = '';
        }
      }

      if (field = 'positionTime') {
        if (!this.calculation.positionTimeYears && !this.calculation.positionTimeMonths && !this.calculation.positionTimeDays) {
          validations.positionTime = 'Campo obrigatório';
          isValid = false;
        } else {
          validations.positionTime = '';
        }
      }

      this.validations = { ...validations };
      return isValid;
    },

    formValidation() {
      let isValid = true;
      const fields = [
        'address',
        'birthDate',
        'city',
        'currentProfession',
        'email',
        'gender',
        'mainPhoneNumber',
        'maritalStatus',
        'name',
        'secondaryPhoneNumber',
        'state',
        'calculationDate',
        'zipcode',
        'publicServiceTime',
        'teachingTime',
        'carreerTime',
        'positionTime',
        'contributionTime',
        'publicServiceIngressDate',
        'contributionTime'
      ]

      fields.forEach((field) => {
        if (!this.inputValidation(field)) {
          isValid = false;
        }
      });

      return isValid;
    },

    fetchWorkRecords() {
      this.loadWorkRecords(this.$route.params.id);
    },

    fetchUpdateIndex() {
      getUpdateIndex()
        .then((response) => {
          this.updateIndex = response.updateIndex;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async fetchCalculation(calculationId) {
      this.isLoading = true;

      const { calculation, customer } = await getCalculationById(calculationId);

      this.customer = customer;
      this.calculation = calculation;
      this.calculation.publicServiceIngressDate = moment(calculation.publicServiceIngressDate).format('DD/MM/YYYY');
      this.calculation.calculationDate = calculation.calculationDate ? moment(calculation.calculationDate).format('DD/MM/YYYY') : moment(calculation.updatedAt).format('DD/MM/YYYY') ;
      this.calculation.createdAt = moment(calculation.createdAt).format('DD/MM/YYYY');

      this.isLoading = false;
    },

    handlerAddRemuneration(record) {
      this.recordSelected = record;
      this.showRemunerationFormModal = true;
    },

    handlerEditWorkRecord(record) {
      this.recordSelected = record;
      this.showCalculationForm = true;
    },

    async handlerRemoveWorkRecord(record) {
      const result = await this.$swal.fire({
        title: 'Tem certeza que deseja remover esse registro?',
        text: 'Esta ação não poderá ser desfeita!',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, pode remover!'
      });

      if (!result.isConfirmed) { return; }

      try {
        await axios.delete(`/calculations/${record.id}`);

        this.$swal.fire(
          'Pronto!',
          `Removido com sucesso!`,
          'success'
        )
        await this.fetchWorkRecords();
      } catch (error) {
        this.$swal.fire(
          'Ops!',
          `Não foi possível remover, tente novamente mais tarde!`,
          'error'
        )
      }
    },

    handlerCloseModal() {
      this.showCalculationForm = false;
      this.recordSelected = null;
    },
  },
}
</script>

<style scoped></style>
