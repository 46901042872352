<template>
  <div class="bg-primary">
    <div class="container text-white">
      <div class="py-5 text-center">
        <img class="d-block mx-auto mb-4" src="../assets/logo.webp">
        <h2>Crie seu Cadastro</h2>
        <p>
          Para criar o seu cadastro basta inserir um nome, um nome de usuário e uma senha de sua escolha!
        </p>
      </div>

      <div class="row">

        <div class="col-md-12 order-md-1">
          <form class="needs-validation" @submit.prevent="register">
            <div class="row">
              <div class="col-md-8 mb-3">
                <div class="row">
                  <div class="col-md-6 mb-3">
                    <label for="firstName">Seu Nome</label>
                    <input type="text" class="form-control" id="firstName" placeholder="" value="" required="" v-model="account.name">
                    <div class="invalid-feedback">
                      Valid first name is required.
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <label for="email">Seu e-mail</label>
                    <div class="input-group">
                      <input type="text" class="form-control" id="email" placeholder="nome@provedor.com" required="" autocomplete="username" v-model="account.email">
                      <div class="invalid-feedback">
                        O email não pode ser vazio.
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <label for="cpf">CPF</label>
                    <the-mask type="text" id="cpf" class="form-control" placeholder="" value="" required="" v-model="account.cpf" :mask="'###.###.###-##'" :masked="false" />
                    <div class="invalid-feedback">
                      CPF necessário
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <label for="phone">Telefone</label>
                    <the-mask
                      type="text"
                      class="form-control"
                      id="phone" placeholder=""
                      value=""
                      required=""
                      v-model="account.phoneNumber"
                      :mask="['(##) ####-####', '(##) #####-####']"
                      :masked="false"
                    />
                    <div class="invalid-feedback">
                      Telefone necessário
                    </div>
                  </div>
                </div>

                <div class="row">

                  <div class="col-md-3 mb-3">
                    <label for="state">Estado</label>
                    <b-form-select v-model="account.state" :options="stateOptions"></b-form-select>
                    <div class="invalid-feedback">
                      Estado necessário
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <label for="city">Cidade</label>
                    <input type="text" class="form-control" id="city" placeholder="" value="" required="" v-model="account.city">
                    <div class="invalid-feedback">
                      Cidade necessário
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <label for="neighborhood">Bairro</label>
                    <input type="text" class="form-control" id="neighborhood" placeholder="" value="" required="" v-model="account.neighborhood">
                    <div class="invalid-feedback">
                      Bairro necessário
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="street">Rua</label>
                    <input type="text" class="form-control" id="street" placeholder="" value="" required="" v-model="account.street">
                    <div class="invalid-feedback">
                      Rua necessária
                    </div>
                  </div>
                  <div class="col-md-2 mb-3">
                    <label for="streetNumber">Número</label>
                    <input type="text" class="form-control" id="streetNumber" placeholder="" value="" required="" v-model="account.streetNumber">
                    <div class="invalid-feedback">
                      Número necessário
                    </div>
                  </div>
                  <div class="col-md-3 mb-3">
                    <label for="zipcode">CEP</label>
                    <the-mask class="form-control" id="zipcode" placeholder="" v-model="account.zipcode" :mask="'#####-###'" :masked='false'/>
                    <div class="invalid-feedback">
                      CEP necessário
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4 mb-3">
                    <label for="password">Senha</label>
                    <input type="password" class="form-control" id="password" autocomplete="password" placeholder="" value="" required="" v-model="account.password" >
                  </div>
                  <div class="col-md-4 mb-3">
                    <label for="confirmPassword">Confirmação de senha</label>
                    <input type="password" class="form-control" id="confirmPassword" autocomplete="password" placeholder="" value="" required="" v-model="account.confirmPassword">
                  </div>
                </div>

                <div class="col-12 m-auto">
                  <b-form-checkbox
                    id="checkbox-1"
                    v-model="termsAndConditions"
                    name="checkbox-1"
                    value="accepted"
                    unchecked-value="not_accepted"
                  >
                    Declaro que li e aceito os <b-link class="text-secondary" v-b-modal.modal-1>termos de uso da plataforma.</b-link class="secondary">

                  </b-form-checkbox>
                </div>

                <div class="col-4 m-auto pt-5">
                  <button class="btn btn-secondary btn-lg btn-block text-center" type="submit">Próximo </button>
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12">
                <div class="single-price">
                  <div class="deal-top">
                    <h3>Licença Anual</h3>
                    <h4>
                      <span class="sup">12x</span> R$ 250,00
                    </h4>
                  </div>
                  <div class="deal-bottom pb-5">
                    <ul class="deal-item">
                      <li>Calculadora previdenciária</li>
                      <li>Video Aulas (Bônus)</li>
                      <li>Mapas mentais (Bônus)</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <hr class="mb-4">

          </form>
        </div>
      </div>
      <div>
        <b-modal id="modal-1" title="TERMOS DE USO DOS SERVIÇOS ORIENTA LEGAL LTDA.">
          <TermsAndConditions />
        </b-modal>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import Footer from 'components/Footer';
import TermsAndConditions from 'components/TermsAndConditions';
import { mapGetters } from 'vuex';

export default {
  name: 'Register',
  computed: mapGetters(['authstatus', 'authErrorMessage']),
  data() {
    return {
      stateOptions: [
        { value: null, text: 'Please select an option' },
        // All Brazil states
        { value: 'AC', text: 'Acre' },
        { value: 'AL', text: 'Alagoas' },
        { value: 'AP', text: 'Amapá' },
        { value: 'AM', text: 'Amazonas' },
        { value: 'BA', text: 'Bahia' },
        { value: 'CE', text: 'Ceará' },
        { value: 'DF', text: 'Distrito Federal' },
        { value: 'ES', text: 'Espírito Santo' },
        { value: 'GO', text: 'Goiás' },
        { value: 'MA', text: 'Maranhão' },
        { value: 'MT', text: 'Mato Grosso' },
        { value: 'MS', text: 'Mato Grosso do Sul' },
        { value: 'MG', text: 'Minas Gerais' },
        { value: 'PA', text: 'Pará' },
        { value: 'PB', text: 'Paraíba' },
        { value: 'PR', text: 'Paraná' },
        { value: 'PE', text: 'Pernambuco' },
        { value: 'PI', text: 'Piauí' },
        { value: 'RJ', text: 'Rio de Janeiro' },
        { value: 'RN', text: 'Rio Grande do Norte' },
        { value: 'RS', text: 'Rio Grande do Sul' },
        { value: 'RO', text: 'Rondônia' },
        { value: 'RR', text: 'Roraima' },
        { value: 'SC', text: 'Santa Catarina' },
        { value: 'SP', text: 'São Paulo' },
        { value: 'SE', text: 'Sergipe' },
        { value: 'TO', text: 'Tocantins' },
      ],
      account: {
        name: '',
        email: '',
        phoneNumber: '',
        state: '',
        city: '',
        neighborhood: '',
        street: '',
        streetNumber: '',
        zipcode: '',
        password: '',
        confirmPassword: ''
      },
      termsAndConditions: 'not_accepted',
    };
  },
  methods: {
    register() {
      const { account } = this;

      if (this.termsAndConditions === 'not_accepted') {
        this.$toast.open({
          message: 'Você precisa aceitar os termos de uso',
          type: 'error',
          position: 'top-right'
        });
        return;
      }

      if (account.zipcode?.length < 8) {
        this.$toast.open({
          message: 'CEP inválido',
          type: 'error',
          position: 'top-right'
        });
        return;
      }

      const handleSuccess = (data) => {
        console.log(data)
        this.$store.dispatch('REGISTER', {
          account,
          checkout: data
        }).then((response) => {
          console.log('Register success');
          this.$router.push('/');
        }).catch( (e) => {
          console.info('Register Error');
          this.$toast.open({
            message: this.$store.getters.authErrorMessage,
            type: 'error',
            position: 'top-right'
          });
        });
      }

      function handleError (data) {
        this.$toast.open({
          message: 'Erro na transação',
          type: 'error',
          position: 'top-right'
        });
      }

      var checkout = new PagarMeCheckout.Checkout({
        // TODO: use env var
        // encryption_key: 'ek_test_B8XNxPksaPdHwzTrSsXQOg8OjRvn9l',
        encryption_key: 'ek_live_0itPS8TwJ0zpYor32rl938uj76Murx',
        success: handleSuccess,
        error: handleError
      });

      checkout.open({
        amount: 300000,
        maxInstallments: 12,
        defaultInstallment	: 12,
        createToken: 'false',
        paymentMethods: 'credit_card',
        customerData: false
      })
    }
  },
  components: {
    Footer,
    TermsAndConditions,
  }
}
</script>

<style>
.single-price {
  text-align: center;
  background: #ed7b02;
  transition: .7s;
  margin-top: 20px;
}
.single-price h3 {
  font-size: 25px;
  color: #000;
  font-weight: 600;
  text-align: center;
  margin: 0;
  margin-top: -80px;
  font-family: poppins;
  color: #fff;
}
.single-price h4 {
  font-size: 48px;
  font-weight: 500;
  color: #fff;
}
.single-price h4 span.sup {
  vertical-align: text-top;
  font-size: 25px;
}
.deal-top {
  position: relative;
  background: #262626 !important;
  font-size: 16px;
  text-transform: uppercase;
  padding: 136px 24px 0;
}
.deal-top::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -50px;
  width: 0;
  height: 0;
  border-top: 50px solid #262626;
  border-left: 175px solid transparent;
  border-right: 170px solid transparent;
}
.deal-bottom {
  padding: 56px 16px 0;
}
.deal-bottom ul {
  margin: 0;
  padding: 0;
}
.deal-bottom  ul li {
  font-size: 16px;
  color: black;
  font-weight: 300;
  margin-top: 16px;
  border-top: 1px solid #E4E4E4;
  padding-top: 16px;
  list-style: none;
}
.btn-area a {
  display: inline-block;
  font-size: 18px;
  color: #fff;
  background: #ed7b02;
  padding: 8px 64px;
  margin-top: 32px;
  border-radius: 4px;
  margin-bottom: 40px;
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;
}


.single-price:hover {
  background: #ed7b02;
}
.single-price:hover .deal-top {
  background: #262626;
}
.single-price:hover .deal-top:after {
  border-top: 50px solid #262626;
}
.single-price:hover .btn-area a {
  background: #262626;
}

/* ignore the code below */
.link-area
{
  position:fixed;
  bottom:20px;
  left:20px;
  padding:15px;
  border-radius:40px;
  background:tomato;
}
.link-area a
{
  text-decoration:none;
  color:#fff;
  font-size:25px;
}
</style>
