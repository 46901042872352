import axios from 'axios';

export async function findById(id) {
  const response = await axios.get(`/users/${id}`);
  return response.data;
}

export async function update(id, data) {
  const response = await axios.put(`/users/${id}`, { user: data });
  return response.data;
}

export async function create(data = {}) {
  const response = await axios.post('/users', { user: data });
  return response.data;
}

export default {
  findById,
  update,
  create,
};
