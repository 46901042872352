<template>
  <div class="border bg-white-blue rounded border-info border-left-4 mb-3">
    <div class="p-2 border-bottom d-flex align-items-center">
      <div>
        <h6 class="mb-2 text-gray-600 font-weight-bold">
          <i class="fa fa-building mr-1" aria-hidden="true"></i> {{item.companyName}}
          <span 
            v-for="tag, index in tags" :id="'tooltip-target-'+index" :key="'tooltip-target-'+index"
            style="
              background-color: #FAFB7F; 
              padding: 2px; 
              margin: 2px; 
              border: 1px solid #E0E072; 
              font-size: 12px;
            "  
          >
            <b-tooltip :target="'tooltip-target-'+index" triggers="hover">{{ tag.tooltip || tag.name }}</b-tooltip>
            {{ tag.name }}
          </span>
        </h6>
        <h6 class="mb-0 text-gray-500 small" v-if="item.ingressDate && item.egressDate">
          Período de {{ duration }}
        </h6>
      </div>

      <div class="ml-auto"></div>

      <button
        class="btn btn-link p-0 btn-sm ml-4"
        title="Adições"
        v-on:click="$emit('addition', item)"
        v-if="canAddition"
      >
        Adições
      </button>

      <button
        class="btn btn-link p-0 btn-sm ml-4"
        title="Deduções"
        v-on:click="$emit('deduction', item)"
      >
        Deduções
      </button>

      <button
        class="btn btn-link p-0 btn-sm ml-4"
        title="Editar"
        v-on:click="$emit('edit', item)"
      >
        <i class="fa fa-edit" aria-hidden="true"></i>
      </button>

      <button
        class="btn btn-link text-danger p-0 btn-sm ml-4"
        title="Remover"
        v-on:click="$emit('remove', item)"
      >
        <i class="fa fa-trash" aria-hidden="true"></i>
      </button>
    </div>

    <div>
      <div class="row align-items-center">
        <div class="col-md-3 border-right">
          <div class="p-2">
            <h6 class="text-uppercase text-gray-600 small">Ingresso</h6>
            <h6 class="text-info mb-0">
              <i class="fa fa-calendar-check-o mr-1" aria-hidden="true"></i>
              {{ ingressDate }}
              <!-- {{item.ingressDate | moment('DD/MM/YYYY')}} -->
            </h6>
          </div>
        </div>
        <div class="col-md-3 border-right">
          <div class="p-2">
            <h6 class="text-uppercase text-gray-600 small">Encerramento</h6>
            <h6 class="text-info mb-0">
              <i class="fa fa-calendar-times-o mr-1" aria-hidden="true"></i>
              {{ egressDate }}
              <!-- {{item.egressDate | moment('DD/MM/YYYY')}} -->
            </h6>
          </div>
        </div>

        <div class="col-md-6">
          <div class="p-2">
            <div
              class="d-flex align-items-center mb-1"
              v-if="fillPercentage <= 100"
            >
              <h6 class="text-uppercase text-gray-600 small mb-0">Remunerações preenchidas</h6>

              <span
                class="text-uppercase small font-weight-bold ml-1 text-gray-600"
              >
                {{ fillNumber }} de {{ total }}
              </span>

              <span
                class="ml-1 font-weight-bold small"
                v-bind:class="completed ? 'text-info': 'text-secondary'"
              >
                ({{fillPercentage}}%)
              </span>

              <button
                class="btn btn-sm ml-auto px-1 py-0"
                v-bind:class="completed ? 'btn-outline-info': 'btn-outline-secondary'"
                v-on:click="$emit('addRemuneration', item)"
              >
                <i class="fa fa-plus" aria-hidden="true"></i>
                Inserir
              </button>
            </div>
            <div class="d-flex align-items-center mb-1" v-else>
              <i class="fa fa-exclamation-triangle text-warning mr-1" aria-hidden="true"></i>
              <h6 class="text-error bold mb-0">Data de início e término inválidas</h6>
            </div>

            <b-progress
              v-if="fillPercentage <= 100"
              v-bind:value="fillPercentage"
              v-bind:max="100"
              v-bind:variant="completed ? 'info': 'secondary'"
              striped
              show-value
            ></b-progress>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'WorkRecordItem',

  props: {
    item: {
      type: Object,
      required: true,
    }
  },

  computed: {
    tags() {
      let tags = [];

      if (this.item.isPublicService) tags.push({ name: "Serviço público" });
      if (this.item.isTeaching) tags.push({ name: "Magistério" });
      if (this.item.isCurrentCareer) tags.push({ name: "Carreira Atual" });
      if (this.item.isCurrentPosition) tags.push({ name: "Cargo Atual" });
      if (this.item.isSpecialTime) tags.push({ name: "Tempo Especial" });

      if (this.item.isDisabled && this.item.disabledLevel) {
        let text = "Com Deficiência";

        switch(this.item.disabledLevel) {
          case "low":
            text += " Leve";
            break;
          case "medium":
            text += " Moderada";
            break;
          case "high":
            text += " Grava";
            break;
        }

        tags.push({ name: text });
      }

      // if (tags.length > 3) {
      //   const extras = tags.slice(3, tags.length);
      //   let extraItems = extras.length;
      //   tags = tags.slice(0, 3);
        
      //   tags.push({ name: `+${extraItems}`, tooltip: extras.map(extra => `${extra.name}`).toString("") });
      // }

      return tags;
    },

    ingressDate() {
      return moment(this.item.ingressDate).utc().format('DD/MM/YYYY');
    },

    egressDate() {
      return moment(this.item.egressDate).utc().format('DD/MM/YYYY');
    },

    canAddition() {
      if (moment(this.item.ingressDate).isAfter(moment("1998-12-16"))) return false;

      return true;
    },  

    salaries() {
      return this.item.salaries || [];
    },

    total() {
      let start = moment(this.item.ingressDate);

      if (start.isSameOrBefore('1994-07-01')) {
        start = moment('1994-07-01');
      }

      const end = moment(this.item.egressDate);
      let months = 0;

      while (start.isSameOrBefore(end)) {
        months++;
        start.add(1, 'month');
      }

      return months;
    },

    fillNumber() {
      const { salaries } = this;
      return salaries.filter((s) => s.value).length;
    },

    fillPercentage() {
      const { salaries, total, fillNumber } = this;

      if (total === 0) return 100;
      if (!salaries.length) return 0;

      return Math.round((fillNumber / total) * 100);
    },

    completed() {
      return this.fillPercentage === 100;
    },

    duration() {
      const { ingressDate, egressDate } = this.item;
      // using moment duration
      const duration = moment.duration(moment(egressDate).diff(moment(ingressDate)));
      const years = duration.years();
      const months = duration.months();
      const days = duration.days();

      return `${years} ano${years > 1 ? 's' : ''} e ${months} mês${months > 1 ? 'es' : ''} e ${days} dia${days > 1 ? 's' : ''}`;
    },
  },
}
</script>

<style scoped>
</style>
