import axios from 'axios';

export async function getAccounts(query = {}) {
  const response = await axios.get('/accounts', query);
  return response.data;
}

export async function inactivateAccount(id) {
  const response = await axios.put(`/accounts/${id}/inactivate`);
  return response.data;
}

export async function activateAccount(id) {
  const response = await axios.put(`/accounts/${id}/activate`);
  return response.data;
}

export async function createAccount(data) {
  const response = await axios.post('/accounts', data);
  return response.data;
}
